import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const SomethingIsDreaming = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">the chief scientist takes his job quite seriously</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-half art something_is_dreaming"></div>
          <div className="column is-one-quarter lyrics">
            <p>Cross the glistening sands <br/>
               eon-etched ocean of time <br/>
               ’neath guardian rock and regolith <br/>
               Something is dreaming </p>

            <p>
               It knows the depths and darkness <br/>
               And it knows what lies above <br/>
               The winds that whirl the dust of ages <br/>
               it ponders life and love
            </p>
            <p>
               Of what does it dream <br/>
               In its cold and empty world? <br/>
               Where does it find its wonders <br/>
               Its visions to behold? <br/>
               Will it find the answers <br/>
               Its plots and fantasies? <br/>
               Of what does it dream? <br/>
               It dreams of thou and thee
            </p>
            <p>
               Prepare the instruments <br/>
               To probe this entity <br/>
               Heed the directed protocols <br/>
               Observe with dignity <br/>
               Care not to impose bias <br/>
               Our wishes and our fears <br/>
               Something dreams a message <br/>
               Let us strive to hear
            </p>
            <p>
               Yet what does it dream <br/>
               what fate does it portend? <br/>
               What power in its thoughts <br/>
               to embrace or to defend? <br/>
               Could it be a danger <br/>
               Red menace come at last? <br/>
               Or is it just a dreamer <br/>
               Evoking a golden past?
            </p>
           <p>
               Silently appearing <br/>
               high above this world <br/>
               Reaching out inquisitively <br/>
               my senses are unfurled<br/>
               Dancing joyous <br/>
               floating free <br/>
               Impulse comes to <br/>
               bind myself to thee
            </p>
          </div>
          <div className="column is-one-quarter lyrics">
             <p>
               Alone I emerge <br/>
               as the stars provide <br/>
               outward facing emptiness <br/>
               In depths my soul resides
            </p>
            <p>
               Detecting shapes and shadows <br/>
               the scientists theorize <br/>
               Outlines form beginnings <br/>
               Thoughts upon the skies <br/>
               Standing waves arising <br/>
               Our buffers overrun <br/>
               A narrative is forming <br/>
               The story has begun
            </p>
            <p>
               What does it dream?<br/>
               What does it dream?<br/>
               Tell me! What does it dream?<br/>
               What does it dream?
            </p>
            <p>
               The sun and the moon <br/>
               The land and the sea <br/>
               Yin and yang <br/>
               Thou and thee
            </p>
            <p>
               Adam and Eve <br/>
               Low and high <br/>
               Loners to lovers <br/>
               Planet and Sky
            </p>
            <p>
               That’s you and me, baby <br/>
               This I will testify <br/>
               Loners to lovers <br/>
               Planet and Sky
            </p>
            <p>
               You and me baby <br/>
               Me oh my <br/>
               Loners to lovers <br/>
               Planet and Sky
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default SomethingIsDreaming
